import { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { FastField as FormikFastField, Field as FormikField, getIn as FormikGetIn } from 'formik';

import getIn from 'Lib/utilities/getIn';
import isEmpty from 'Lib/utilities/isEmpty';
import isFunction from 'Lib/utilities/isFunction';

const Field = props => {
    const { id, name, label, hideLabel, labelClassName, maxLength, helperText, className, style, fast = true, render, ...rest } = props;
    const idRef = useRef(id || name);

    useEffect(() => {
        const invalidFields = document.getElementsByClassName('form-field is-invalid is-required');
        invalidFields[0]?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    });

    const renderLabel = () => {
        if (label && !rest.isMui) {
            const attrs = {
                className: classNames(labelClassName, { 'sr-only': hideLabel }),
                htmlFor: idRef.current
            };

            return <label {...attrs}>{label}</label>;
        }
    };

    const renderInput = fieldProps => {
        if (render) {
            const combinedProps = {
                ...props,
                ...fieldProps,
                id: idRef.current
            };

            delete combinedProps.fast;
            if (combinedProps.isMui) delete combinedProps.isMui;

            return render(combinedProps);
        }
    };

    const renderError = ({ form, field }) => {
        let errors = getIn(form.errors, field.name);

        if (!errors || errors.length === 0) return;
        if (!Array.isArray(errors)) errors = [errors];

        return (
            <div className="form-field-error">
                {errors.map((error, index) => (
                    <div key={index}>{error}</div>
                ))}
            </div>
        );
    };

    const renderHelper = ({ form, field }) => {
        let helper = helperText;
        const value = FormikGetIn(form.values, field.name);
        const errors = FormikGetIn(form.errors, field.name);

        let valueLength = 0;

        if (value && value.length) valueLength = value.length;

        if (isFunction(helper)) helper = helper();

        if (isEmpty(helper) && !(maxLength > 0) && !errors) return;

        return (
            <div className="form-field-helper">
                <div className="form-field-helper-text">
                    <em>{helper}</em>
                    {renderError({ form, field })}
                </div>
                {maxLength > 0 ? (
                    <div className="form-field-helper-length">
                        {valueLength} / {maxLength}
                    </div>
                ) : null}
            </div>
        );
    };

    const renderField = fieldProps => {
        const { form, field } = fieldProps;
        const value = FormikGetIn(form.values, field.name);
        const error = FormikGetIn(form.errors, field.name);
        const attrs = {
            className: classNames('form-field', className, {
                'has-value': !isEmpty(value),
                'is-invalid': !isEmpty(error) || (Array.isArray(error) && error.length > 0),
                'is-required': props.isRequired
            }),
            style
        };

        if (error) fieldProps.error = error;

        return (
            <div {...attrs}>
                {renderLabel()}
                <div className={error ? 'is-invalid' : 'is-valid'}>{renderInput(fieldProps)}</div>
                {props.disableHelperText ? null : renderHelper(fieldProps)}
            </div>
        );
    };

    if (fast) {
        return (
            <FormikFastField name={name} {...rest}>
                {fieldProps => renderField(fieldProps)}
            </FormikFastField>
        );
    }

    return (
        <FormikField name={name} {...rest}>
            {fieldProps => renderField(fieldProps)}
        </FormikField>
    );
};

export default Field;
